.navLinks {
  border: 4px solid var(--light);
  padding: 3px 3px 15px 3px !important;
  margin: 15px 5px 0px 5px !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.navLinks.active {
  color: var(--blue) !important;
}

.dropLink.active {
  background-color: transparent !important;
}

.notActive {
  border: 0px solid var(--blue) !important;
  background-color: none !important;
}

#linkWrap {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

#navDropdown {
  padding: 0px !important;
  color: var(--darkBlue);
}

#navbar {
  border-bottom: 5px solid var(--blue) !important;
  background-color: var(--light) !important;
  padding: 0 !important;
}

#navBrand {
  margin: 5px 0px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke='rgba(35, 55, 77, 1)' stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 2px !important;
}

.navbar-light .navbar-toggler {
  color: var(--darkBlue) !important;
  border-color: var(--darkBlue) !important;
}

/* This media query is for where the navBar breaks to toggle mode. */
@media only screen and (min-width: 768px) {
  .navLinks:hover {
    border: 4px solid var(--blue);
    background-color: var(--blue) !important;
    color: var(--lightest) !important;
  }

  #navDropdown:hover {
    color: var(--lightest);
  }

  .dropLink:hover {
    background-color: var(--blue) !important;
    color: var(--lightest) !important;
  }

  .navLinks.active {
    border: 4px solid var(--blue);
    background-color: var(--blue);
    color: var(--lightest) !important;
  }

  .dropLink.active {
    border: 0px solid var(--blue) !important;
    background-color: transparent !important;
  }

  .navLinks.show {
    border: 4px solid var(--blue) !important;
    background-color: var(--blue) !important;
  }
}