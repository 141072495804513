footer {
  border-top: 5px solid #1089FF;
  background-color: var(--light);
}

footer a {
  text-decoration: none;
  color: black;
}

footer li {
  list-style: none;
}

footer ul {
  padding: 0px;
}

.linkIcon {
  color: #1089FF !important;
}

.thumbtack {
  border-left: 2px solid black;
  border-right: 2px solid black;

}

.links a:hover {
  color: #1089FF;
}