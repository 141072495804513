#navWrapper {
  position: sticky !important;
  top: 66px !important;
  background-color: var(--light);
  border-bottom: 1px solid var(--darkBlue);
  height: 100% !important;
  width: 100%;
  padding: 10px 0;
  z-index: 1000;
}

#sidebar {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap;
  justify-content: space-around !important;
  background-color: var(--light);
  height: 100% !important;
  width: 100%;
}

.sideNavLink {
  width: 50px !important;
  height: 50px !important;
  padding: 0 !important;
}

.sideNavLink.active {
  border: 1px solid var(--darkBlue);
}

.sidebarIcons {
  width: 43px !important;
  height: 43px !important;
  margin: 2.5px !important;
}

.sidebarLinks {
  display: none !important;
}

#hr {
  margin: 0 !important;
}

#serviceLayout {
  background-color: var(--light);
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  #navWrapper {
    top: 70.4px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .navWrapper {
    height: 70px !important;
  }
}

@media only screen and (min-width: 1025px) and (min-height: 850px) {
  #serviceLayout {
    flex-direction: row;
  }

  #navWrapper {
    position: sticky !important;
    top: 70px !important;
    height: calc(100vh - 190px) !important;
    width: 125px !important;
    border-bottom: none;
    margin-right: 12px;
    margin-bottom: 0px !important;
    padding: 0 !important;
    z-index: 1000;
  }

  #sidebar {
    flex-direction: column !important;
    text-align: center !important;
  }

  .sidebarIcons {
    width: 65px !important;
    height: 65px !important;
  }

  .sideNavLink {
    width: 100px !important;
    height: 100px !important;
    margin: auto;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    /* align-items: center !important; */
  }

  .sidebarLinks {
    display: inline-block !important;
    padding: 0 !important;
    line-height: normal;
    text-decoration: none !important;
  }
}
