#joe {
  flex-basis: 33.33%;
  border: 2px solid var(--darkBlue);
}

#aboutText {
  flex-basis: 66.66%;
}

@media only screen and (max-width: 1024px) {
  #aboutText {
    text-align: center;
    padding: 0px !important;
    flex: 1 1 auto!important;
  }
}

@media only screen and (max-width: 480px) {
  #aboutSection {
    margin: 0px 15px 15px 15px !important;
  }
}