#carousel {
	border: 1px solid var(--darkBlue);
	border-radius: 0.24rem !important;
	background-color: var(--lightest);
}

.carousel-caption {
	position: relative !important;
	left: auto !important;
	right: auto !important;
	border-top: 1px solid var(--darkBlue);
}

.carouselImage {
	max-height: 65vh;
	margin: auto;
}

.carousel-caption {
	right: 0% !important;
	left: 0% !important;
	bottom: 0% !important;
	background-color: var(--lightest);
	padding-top: 0px !important;
	padding-bottom: 5px !important;
}

.carousel-indicators {
	margin-bottom: 0px !important;
}
