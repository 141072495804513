:root {
  --darkBlue: #23374d;
  --blue: #1089ff;
  --light: #e5e5e5;
  --lightest: #eeeeee;
}

/* This css makes sure the footer stays at the bottom. */
.contentContainer {
  min-height: calc(100vh - 119px);
  background-color: var(--lightest);
}

.blueSpan {
  color: var(--blue);
}

.text {
  color: var(--darkBlue) !important;
}

.textTest {
  color: red !important;
}

.fontLight {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
}

.font {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
}

.fontBold {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
}

#website {
  background-color: var(--lightest);
}

header {
  background-color: var(--lightest);
}