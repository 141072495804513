.imageWrapper {
  position: relative;
}

.caption {
  position: absolute;
  display: inline;
  top: 0%;
  right: 0%;
  left: 0%;
  background-color: rgba(229, 229, 229, 0.8);
  color: var(--darkBlue) !important;
  font-size: 2.9vh !important;
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .subColumns {
    flex: 0 0 auto !important;
    width: 50% !important;
  }
}

@media only screen and (min-width: 482px) {
  #mainWrapper {
    text-align: center;
  }
}